import { init } from '@/util-functions/initialization-util';
import { DocumentData, DocumentReference, FieldValue, Timestamp } from 'firebase/firestore';
import {
  eCardStyle,
  eChargeStatuses,
  eCustomFieldTypes,
  eDeliveryTypes,
  eInteractionDeviceTypes,
  eMenuIconTypes,
  eMetaTheme,
  eModeType,
  eOrderStatuses,
  eTaxTypes,
  eThemes,
  eTranslationLocaleCode,
  eWeekdays,
} from './enums';

// Does this pollute the global namespace? Sure, but I like the pros over the cons.
declare global {
  interface String {
    supplant(o: string[]): string;
  }
  interface ModesMap {
    [modeId: string]: AnyMode;
  }
  interface StripeAccount {
    stripeUserId: string;
    refreshToken: string;
    livemode: boolean;
    scope: string;
    email: string;
    statementDescriptor: string;
    displayName: string;
    customerFacingBusinessName: string;
    url: string;
    phone: string;
    country: string;
    defaultCurrency: string;
  }
  interface User {
    docId: string;
    photoUrl: string;
    displayName: string;
    email: string;
    modeOrder: string[];
    modeShortCut1: string;
    modeShortCut2: string;
    folderOrder: string[];
    folders: FoldersMap;
    additionalVerifiedEmails: string[];
    permalinks?: {
      [modeId: string]: string;
    };
    storageQuota: number;
    lastAffiliateProgramRequestSubmissionDate: FieldValue | null;
    settings: UserSettings;
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }
  interface UserSettings {
    locale: eTranslationLocaleCode;
    appTheme: eThemes;
    showNewPaymentAlert: boolean;
    showNewBookingAlert: boolean;
    showNewCustomFormAlert: boolean;
    showNewMessageAlert: boolean;
  }
  interface FoldersMap {
    [folderName: string]: Folder;
  }
  interface Folder {
    id: string;
    name: string;
    modeOrder: string[];
    dateCreated: number;
  }
  interface AestheticTheme extends ItemWithImages {
    id: string;
    name: string;
    backgroundColor: string;
    accentColor: string;
    primaryFontName: string;
    headerFontName: string;
    primaryFontVariant: FontVariant;
    headerFontVariant: FontVariant;
    primaryFontScale: number;
    headerFontScale: number;
    uppercaseHeaderFont: boolean;
    cardStyle: eCardStyle;
    ambientText: string;
    metaTheme: eMetaTheme;
  }
  interface PublicUserModeGateway {
    docId: string;
    activeModeId: string; // This will be a sitch.app url if the active mode is from a joined team.
    wrapperModeId: string; // This will be a sitch.app url if the wrapper mode is from a joined team.
    activeModeLinkId: string;
    wrapperModeLinkId: string;
    activeModeOwnerId: string;
    wrapperModeOwnerId: string;
    isSitchLinkActivated: boolean;
    hasPermanentPremium: boolean;
    loadNewActiveModesAutomatically: boolean;
    autoSitchTimer: number; // In ms.
    premiumSubscriptionId: string;
    stripeAccountsMap: { [stripeAccountId: string]: StripeAccount } | null;
    themes: {
      [id: string]: AestheticTheme;
    };
    teamOwnerGatewayRef: DocumentReference<DocumentData> | null;
    assignedTeamThemeId: string;
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }
  interface UserPaymentRecord {
    referenceId: string;
    paymentIntentId: string;
    stripeReceiptUrl: string | null;
    isLive: boolean;
    orderBreakdown: OrderItem[];
    platformUserId: string; // User ID on the Sitch platform.
    stripeUserId: string;
    formattedBaseAmount: string;
    formattedDiscountAmount: string;
    formattedTaxAmount: string;
    formattedTipAmount: string;
    formattedTotalAmount: string;
    formattedDeliveryFee: string;
    formattedRefundAmount: string;
    formattedDate: string;
    amount: number;
    discountAmount: number;
    taxAmount: number;
    tipAmount: number;
    totalAmount: number;
    currency: string;
    chargeStatus: eChargeStatuses;
    chargeId: string;
    creditCardBrand: string | null | undefined;
    creditCardLastFour: string | null | undefined;
    refundAmount: number;
    deliveryFee: number;
    deliveryAddress: DetailedAddress | null;
    modeId: string;
    personWhoPaidEmail: string;
    promoCodes: string[];
    locale: string;
    originalOrderBreakdownChecksum: string;
    retrievedOrderBreakdownChecksum: string;
    errors: string[];
    orderStatus: eOrderStatuses;
    dateCreated: Timestamp;
  }
  interface GlobalPaymentRecord extends UserPaymentRecord {
    displayName: string;
    customerFacingBusinessName: string;
    businessEmail: string;
    businessUrl: string;
    businessPhone: string;
    statementDescriptor: string | null;
    paymentIntent: any; //Stripe.PaymentIntent;
  }
  interface DetailedAddress {
    name?: string;
    phoneNumber?: string;
    formattedAddress: string;
    address: GoogleAddress | null;
    addressLine2: string | null;
    additionalAddressDetails: string;
    latitude: number | null;
    longitude: number | null;
    googlePlaceId: string;
    utcOffset: number;
  }
  interface GoogleAddress {
    administrativeAreaLevel1: string; // State
    administrativeAreaLevel2: string; // City
    administrativeAreaLevel3: string;
    administrativeAreaLevel4: string;
    administrativeAreaLevel5: string;
    streetAddress: string;
    intersection: string;
    political: string;
    country: string;
    colloquialArea: string;
    locality: string;
    sublocality: string;
    sublocalityLevel1: string;
    sublocalityLevel2: string;
    sublocalityLevel3: string;
    sublocalityLevel4: string;
    sublocalityLevel5: string;
    neighborhood: string;
    postalCode: string;
    route: string;
    streetNumber: string;
    premise: string;
    subpremise: string;
    plusCode: string;
    floor: string;
  }
  interface Mode {
    docId: string;
    linkId: string;
    type: eModeType | null;
    name: string;
    displayName: string;
    index: number;
    dateUpdated?: Timestamp;
    dataCreated?: Timestamp;
    isForUpdate?: boolean;
    themeId: string;
  }
  interface SiteMode extends Mode, ItemWithImages {
    menuIconType: eMenuIconTypes;
    emojiIcons: string[];
    landingPageModeId: string;
    sitePageModeIds: string[]; // Other modes to act as pages for the site. Mode ids.
  }
  interface GroupMode extends Mode {
    groupModeIds: string[];
  }
  interface UrlRedirectMode extends Mode {
    redirectUrl: string;
    putInIframe: boolean;
  }
  interface SubmissionMode extends Mode {
    preSubmissionCustomFormModeId: string;
    postSubmissionCustomFormModeId: string;
  }
  interface ShopMode extends StripeEnabledMode, ItemWithImages {
    checkoutSuccessMessage: string;
    requiresDeliveryAddress: boolean;
    flatDeliveryFee: number;
    hasDeliveryTaxRate: boolean;
    deliveryTaxRate: number;
    shopItemList: ShopItem[];
    preCheckoutOrderBreakdown: OrderItem[];
    hasPromoCodes: boolean;
    allowSpecialRequests: boolean;
    categories: Category[];
    allowedCountriesForDelivery: string[];
    freeShippingCountries: string[];
    hasMaxOrderDistance: boolean;
    maxOrderDistance: number;
    locationAddress: DetailedAddress;
    taxType: eTaxTypes;
    computeTaxRateBasedOnLocation: boolean;
    locationsToComputeTaxRateFor: {
      [taxLocationCode: string]: number; // number is the rate charged.
    };
    deliveryType: eDeliveryTypes; // local or shipping
  }
  interface BusinessPaymentsMode extends StripeEnabledMode {
    amount: number;
  }
  interface StripeEnabledMode extends SubmissionMode {
    message: string;
    currency: string;
    stripeAccountId: string;
    tipOptionsEnabled: boolean;
    taxRate: number;
    requireName: boolean;
    // Shop only properties:
    computeTaxRateBasedOnLocation?: boolean;
    locationsToComputeTaxRateFor?: {
      [taxLocationCode: string]: number; // number is the rate charged.
    };
    requiresDeliveryAddress?: boolean;
    flatDeliveryFee?: number;
    hasDeliveryTaxRate?: boolean;
    deliveryTaxRate?: number;
  }
  interface PersonalPaymentsMode extends Mode {
    amount: number;
    currency: string;

    //Crypto
    bitcoinAddress: string;
    bitcoinEnabled: boolean;
    ethereumAddress: string;
    ethereumEnabled: boolean;
    litecoinAddress: string;
    litecoinEnabled: boolean;

    // Other
    interacEnabled: boolean;
    interacEmail: string;
    interacPhoneNumber: string;
    interacPassword: string;
    paypalEnabled: boolean;
    paypalMeUrl: string;
    googlePayEnabled: boolean;
    googlePayMeUrl: string;
    applePayEnabled: boolean;
    applePayMeUrl: string;
    venmoEnabled: boolean;
    venmoUrl: string;
    cashAppEnabled: boolean;
    cashAppUrl: string;
  }
  interface FilesMode extends Mode {
    message: string;
    hideIcons: boolean;
    files: StorageFile[];
  }
  interface LinkListMode extends Mode, ItemWithImages {
    message: string;
    links: Link[];
  }
  interface WifiMode extends Mode {
    ssid: string;
    wifiEncryption: string;
    wifiPassword: string;
  }
  interface GalleryMode extends Mode {
    message: string;
    galleryItems: GalleryItem[];
    galleryItemNamesEnabled: boolean;
  }
  interface BlogMode extends Mode {
    message: string;
    postReferences: BlogPostReference[];
  }
  interface AccordionMode extends Mode {
    message: string;
    sections: AccordionSection[];
  }
  interface BlogPostContent {
    content: string;
  }
  interface ProfileMode extends Mode, ItemWithImages {
    title: string;
    email: string;
    phone: string;
    phoneWork: string;
    phoneWorkExtension: string;
    organization: string;
    organizationUrl: string;
    website: string;
    websiteLabel: string;
    ctaButtonLabel: string;
    ctaButtonUrl: string;
    description: string;
    locationAddress: DetailedAddress | null;
    locationLabel: string;
    showLabelsForSocials: boolean;
    showAddAsContactPrompt: boolean;
    showContactDetailsPrompt: boolean;
    allowPeopleToSendTheirContactDetails: boolean;
    contactDetailsCustomFormModeId: string;
    contactDetailsFormUrl: string;
    hidePhone: boolean;
    hidePhoneWork: boolean;
    hideEmail: boolean;
    hideWebsite: boolean;
    hideOrganization: boolean;
    hideLocationAddress: boolean;
    hideSaveForLaterButton: boolean;
    googleUrl?: string;
    isGoogleVisible?: boolean;
    linkedInUrl?: string;
    isLinkedInUrlVisible?: boolean;
    facebookUrl?: string;
    isFacebookUrlVisible?: boolean;
    redditUrl?: string;
    isRedditUrlVisible?: boolean;
    deviantArtUrl?: string;
    isDeviantArtUrlVisible?: boolean;
    pinterestUrl?: string;
    isPinterestUrlVisible?: boolean;
    fiveHundredPxUrl?: string;
    isFiveHundredPxUrlVisible?: boolean;
    meetupUrl?: string;
    isMeetupUrlVisible?: boolean;
    youtubeUrl?: string;
    isYoutubeUrlVisible?: boolean;
    tikTokUrl?: string;
    isTikTokUrlVisible?: boolean;
    vimeoUrl?: string;
    isVimeoUrlVisible?: boolean;
    soundcloudUrl?: string;
    isSoundCloudUrlVisible?: boolean;
    mixcloudUrl?: string;
    isMixcloudUrlVisible?: boolean;
    bandcampUrl?: string;
    isBandcampUrlVisible?: boolean;
    spotifyUrl?: string;
    isSpotifyUrlVisible?: boolean;
    discordUrl?: string;
    isDiscordUrlVisible?: boolean;
    slackUrl?: string;
    isSlackUrlVisible?: boolean;
    whatsappUrl?: string;
    isWhatsAppUrlVisible?: boolean;
    facebookMessengerUrl?: string;
    isFacebookMessengerUrlVisible?: boolean;
    weChatUrl?: string;
    isWeChatUrlVisible?: boolean;
    clubhouseUrl?: string;
    isClubhouseUrlVisible?: boolean;
    telegramUrl?: string;
    isTelegramUrlVisible?: boolean;
    steamUrl?: string;
    isSteamUrlVisible?: boolean;
    playstationUrl?: string;
    isPlaystationUrlVisible?: boolean;
    xboxUrl?: string;
    isXboxUrlVisible?: boolean;
    itchIoUrl?: string;
    isItchIoUrlVisible?: boolean;
    battleNetUrl?: string;
    isBattleNetUrlVisible?: boolean;
    patreonUrl?: string;
    isPatreonUrlVisible?: boolean;
    kickstarterUrl?: string;
    isKickstarterUrlVisible?: boolean;
    playStoreUrl?: string;
    isPlayStoreUrlVisible?: boolean;
    appStoreUrl?: string;
    isAppStoreUrlVisible?: boolean;
    microsoftUrl?: string;
    isMicrosoftUrlVisible?: boolean;
    amazonUrl?: string;
    isAmazonUrlVisible?: boolean;
    ebayUrl?: string;
    isEbayUrlVisible?: boolean;
    shopifyUrl?: string;
    isShopifyUrlVisible?: boolean;
    etsyUrl?: string;
    isEtsyUrlVisible?: boolean;
    airbnbUrl?: string;
    isAirbnbUrlVisible?: boolean;
    bitcoinUrl?: string;
    isBitcoinUrlVisible?: boolean;
    paypalUrl?: string;
    isPaypalUrlVisible?: boolean;
    cashAppUrl?: string;
    isCashAppUrlVisible?: boolean;
    googlePayUrl?: string;
    isGooglePayUrlVisible?: boolean;
    applePayUrl?: string;
    isApplePayUrlVisible?: boolean;
    githubUrl?: string;
    isGithubUrlVisible?: boolean;
    gitlabUrl?: string;
    isGitlabUrlVisible?: boolean;
    npmUrl?: string;
    isNpmUrlVisible?: boolean;
    bitbucketUrl?: string;
    isBitbucketUrlVisible?: boolean;
    stackOverflowUrl?: string;
    isStackOverflowUrlVisible?: boolean;
    wordpressUrl?: string;
    isWordpressUrlVisible?: boolean;
    productHuntUrl?: string;
    isProductHuntUrlVisible?: boolean;
    quoraUrl?: string;
    isQuoraUrlVisible?: boolean;
    yelpUrl?: string;
    isYelpUrlVisible?: boolean;
    bookTimeUrl?: string;
    isBookTimeUrlVisible?: boolean;
    payUrl?: string;
    isPayUrlVisible?: boolean;
    shopUrl?: string;
    isShopUrlVisible?: boolean;
    portfolioUrl?: string;
    isPortfolioUrlVisible?: boolean;
    resumeUrl?: string;
    isResumeUrlVisible?: boolean;
    twitterHandle?: string;
    isTwitterHandleVisible?: boolean;
    blueskyHandle?: string;
    isBlueskyHandleVisible?: boolean;
    instagramHandle?: string;
    isInstagramHandleVisible?: boolean;
    threadsHandle?: string;
    isThreadsHandleVisible?: boolean;
    snapchatHandle?: string;
    isSnapchatHandleVisible?: boolean;
    twitchHandle?: string;
    isTwitchHandleVisible?: boolean;
    onlyFansHandle?: string;
    isOnlyFansHandleVisible?: boolean;
    mediumHandle?: string;
    isMediumHandleVisible?: boolean;
  }
  interface HtmlMode extends Mode {
    html: string;
    js: string;
    css: string;
    minHeight: string;
    maxWidth: string;
  }
  interface ChessMode extends Mode {
    allowShowThreatsOption: boolean;
  }
  interface WordleMode extends Mode {
    pointGoal: number;
  }
  interface TriviaMode extends Mode {
    numberOfQuestions: number;
    difficulty: string;
    category: string;
  }
  interface CardMode extends Mode, ItemWithImages {
    firstText: string;
    secondText: string;
  }
  interface ArticleMode extends Mode, ItemWithImages {
    text: string;
  }
  interface EventsMode extends Mode {
    message: string;
    events: SitchEvent[];
    contactName?: string;
  }
  interface CustomField {
    id: string;
    name: string;
    type: eCustomFieldTypes;
    description: string;
    choices: string[];
    isRequired: boolean;
    signatureStatement: string; // Only used for signature types.
  }
  interface EmailableMode extends Mode {
    emailForReceivingNotifications: string;
  }
  interface CustomFormMode extends EmailableMode {
    message: string;
    requireName: boolean;
    requireEmail: boolean;
    requirePhoneNumber: boolean;
    customFields: CustomField[];
  }
  interface ChatMode extends Mode {
    message: string;
    qrMessage: string;
    hasAutoTranslate: boolean;
    maximumParticipants: number;
  }
  interface ChatRoom {
    docId: string;
  }
  interface ReturnToMode extends Mode {
    message: string;
    email: string;
    phone: string;
    phoneWork: string;
    locationAddress: DetailedAddress;
  }
  interface ProductMode extends Mode {
    email: string;
    phone: string;
    images: UploadedImage[];
    price: string;
    description: string;
  }
  interface BookingMode extends EmailableMode, SubmissionMode {
    message: string;
    organizationName: string;
    availabilityTimeSlots: TimeSlot[];
    maxBookingsPerDay: number | null;
    bufferTimeBeforeInMinutes: number; // Minimum time before events
    bufferTimeAfterInMinutes: number; // Minimum time after events
    minimumSchedulingNoticeInMinutes: number; // Prevent events less than n minutes away.
    maximumSchedulingNoticeInDays: number; // Prevent events more than n days away.
    timeSteps: 15 | 30 | 60; // In minutes.
    maxSimultaneousBookingsForSameSlot: number;
    durationPerBookingHours: number;
    durationPerBookingMinutes: number;
    closureDates: string[];
    locationAddress: DetailedAddress;
  }
  type AnyMode =
    | CustomFormMode
    | ProfileMode
    | WifiMode
    | FilesMode
    | PersonalPaymentsMode
    | BusinessPaymentsMode
    | UrlRedirectMode
    | SiteMode
    | HtmlMode
    | ShopMode
    | ArticleMode
    | EventsMode
    | BookingMode
    | GalleryMode;
  interface ModeItem {
    id: string;
    isHidden: boolean;
  }
  interface Category extends ModeItem {
    name: string;
    shopItemIds: string[];
  }
  interface PromoCode extends ModeItem {
    code: string;
    discountPercentage: number;
  }
  interface Link extends ItemWithImages, ModeItem {
    name: string;
    url: string;
    description: string;
    isEmbedded: boolean;
  }
  interface TimeSlot extends ModeItem {
    timeOfDayStart: string;
    timeOfDayEnd: string;
    daysOfWeek: eWeekdays[];
  }
  interface SitchEvent extends ItemWithImages, ModeItem {
    name: string;
    eventDate: string;
    startTime: string;
    durationHours: number;
    durationMinutes: number;
    url: string;
    description: string;
    locationAddress: DetailedAddress;
  }
  interface AccordionSection extends ModeItem {
    name: string;
    text: string;
  }
  interface GalleryItem extends ItemWithImages, ModeItem {
    name: string;
    url?: string;
    description?: string;
  }
  interface BlogPostReference extends ItemWithImages, ModeItem {
    name: string;
    subtitle: string;
    author: string;
    goLiveDate: string;
    goLiveTime: string;
    utcOffset: number;
  }
  interface StorageFile extends ModeItem {
    base64ImagePreview?: string;
    mimeType: string;
    fileName: string;
    storagePath: string;
    downloadUrl: string;
  }
  interface CompressedFile {
    id: string;
    mimeType: string;
    fileName: string;
    description: string;
    file: File;
    base64ImagePreview?: string;
    preview?: string;
  }
  interface ShopItem extends ItemWithImages, ModeItem {
    name: string;
    isHidden: boolean;
    isSoldOut: boolean;
    price: number;
    description: string;
    isAgeGated: boolean;
    minimumAge: number;
    modifierGroups: ModifierGroup[];
    minimumQuantity: number;
    maximumQuantity: number;
    hasUniqueTaxRate: boolean;
    uniqueTaxRate: number;
    hasStock: boolean;
    stock: number;
  }
  interface ModifierGroup {
    id: string;
    name: string;
    description: string;
    isHidden: boolean;
    isSoldOut: boolean;
    modifiers: Modifier[];
    numberOfSelections: number;
    mustPickExactAmount: boolean;
    canSelectMultipleOfSameModifier: boolean;
    userOptedToSelectLessThanMax?: boolean; // Delete this before saving, only used to customer checkout.
  }
  interface Modifier {
    id: string;
    name: string;
    isHidden: boolean;
    isSoldOut: boolean;
    amountChange: number;
    requiresCustomerInput: boolean;
    customerInputLabel: string;
    hasStock: boolean;
    stock: number;
    // Only for store fronts, not needed on forms.
    quantitySelected: number;
    customerInput: string;
  }
  interface OrderItem {
    id: string;
    name: string;
    shopItemId: string;
    specialRequest: string;
    quantity: number;
    baseAmount: number; // ShopItem price.
    amountWithModifiers: number; // With OrderedModifier quantities factored in.
    totalAmount: number; // With OrderItem quantity factored in.
    totalAmountString: string;
    totalTaxAmount: number;
    totalAmountWithTax: number;
    selectedModifiers: SelectedModifiers;
    hasUniqueTaxRate: boolean;
    uniqueTaxRate: number;
    progenitorMinimumQuantity: number;
    logoUrl?: string; // For the Sitch store and custom logo card item only.
    logoSideForegroundColor?: string; // For the Sitch store and custom logo card item only.
  }
  interface SelectedModifiers {
    [key: string]: OrderedModifier[];
  }
  interface OrderedModifier {
    name: string;
    modifierId: string;
    amountChange: number;
    customerInput: string;
    quantity: number;
  }
  interface ImageSizes {
    small?: number;
    large?: number;
  }
  interface ItemWithImages {
    images: UploadedImage[];
    secondaryImages?: UploadedImage[];
  }
  interface UploadedImage {
    id: string;
    caption: string;
    toBeUploaded?: boolean; // Font end UI flag for deciding what preview to show. Delete this before uploading.
    markedForDeletion?: boolean; // A flag to mark the image to be deleted in the handleImages function. This flag overrides all other fields in determining an images fate.
    smallImageBase64Preview?: string; // The image base64 encoded to the preview the image before being uploaded
    largeImageBase64Preview?: string; // The image base64 encoded to the preview the image before being uploaded
    smallImageUrl?: string; // The url used to show an uploaded image on the frontend.
    smallImageStoragePath?: string; // Location of the image in the firestore bucket once it has been uploaded. For future image deletion if ncessary
    smallImageFileName?: string; // Randomly generated ID. Does not use the original filename of the uploaded image file.
    smallCompressedImage?: File | null; // Raw file data for the image being uploaded after dimension compression. Delete this before uploading.
    largeImageUrl?: string; // The url used to show an uploaded image on the frontend.
    largeImageStoragePath?: string; // Location of the image in the firestore bucket once it has been uploaded. For future image deletion if ncessary
    largeImageFileName?: string; // Randomly generated ID. Does not use the original filename of the uploaded image file.
    largeCompressedImage?: File | null; // Raw file data for the image being uploaded after dimension compression. Delete this before uploading.
  }
  interface CompressedFileObject {
    compressedFile: File;
    base64data: string;
  }
  interface TextValue {
    text: string;
    value: any;
  }
  interface Font {
    name: string;
    variants: string[];
  }

  type TableHeaders = { text: string; value?: string; sortable?: boolean }[];

  interface Submission {
    docId: string;
    dateCreated: Timestamp;
  }
  interface SubmissionPageMixinData {
    currSelectedMode: null | Mode; // Populated by the select dropdown on each submission review page.
    submissionArray: Submission[];
    dateRangeMenu: boolean;
    dateRange: string[];
    periodSpecified: boolean;
    currSubmission: Submission | null;
    mostRecentSubmission: Submission | null;
    showSubmissionDetailsDialog: boolean;
    mostRecentSubmissionDateInMs: number;
    modeWasJustLoaded: boolean;
    watchedDoc: (() => void) | null;
  }
  interface ModeMixinMethods {
    pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData: () => void;
    postDeletionPreviewArray: (array: { id: string }[]) => any[];
  }
  interface ModeItemFormData {
    onMarkForDeletion: (itemId: string, arrayPropName: string) => void;
    onUnmarkForDeletion: (itemIdToRemove: string) => void;
    itemsToBeRemovedById: string[];
    imageSizes: ImageSizes | undefined;
  }
  interface InteractionData {
    name: string;
    viewCount: FieldValue;
    dayOfWeekViewCounts: {
      [dayOfWeek: number]: FieldValue;
    };
    hourOfDayViewCounts: {
      [hourOfDay: number]: FieldValue;
    };
    monthOfYearViewCounts: {
      [monthOfYear: number]: FieldValue;
    };
    yearViewCounts: {
      [year: number]: FieldValue;
    };
  }
  interface ModeAssignment {
    name?: string;
    assignedModeId?: string;
    assignedLinkId?: string;
    assignedModeOwnerId?: string;
  }
  interface DeviceInfo {
    interactionDeviceType: eInteractionDeviceTypes;
    name: string;
    assignedModeId?: string;
    assignedLinkId?: string;
    assignedModeOwnerId?: string;
    isMultiPartDevice?: boolean; // If this is true this is a single logical device broken up into multiple manageable parts.
    multiPartModeAssignments?: {
      [id: string]: ModeAssignment;
    };
  }
  interface LinkedDevice extends DeviceInfo {
    dateActivated: number;
    genericId: string;
  }
  interface GenericIdData extends DeviceInfo {
    dateCreated: Timestamp;
    dateUpdated: Timestamp;
    dateActivated?: Timestamp;
    userId: string;
    affiliate?: string;
  }
  interface Permalink {
    userId: string;
    modeId: string;
  }
  interface AnalyzableObject {
    id: string;
    url?: string;
    name?: string;
  }
  interface SitchApisuccessfulResponse {
    successfulResponse: any;
  }
  interface SitchApiErrorResponse {
    errorMessage: string;
    error?: any;
  }

  interface CustomFormSubmission {
    docId?: string;
    name: string;
    email: string;
    phoneNumber: string;
    customFields: {
      [id: string]: CustomFieldData;
    };
    userId: string;
    modeId: string;
    dateCreated?: Timestamp;
  }
  interface SignatureValue {
    statement: string;
    image: string;
  }
  type CustomFieldValue = string | boolean | number | CompressedFile[] | StorageFile[] | SignatureValue | undefined;
  interface CustomFieldData {
    name: string;
    type: string;
    value: CustomFieldValue;
  }
  interface LogoImage {
    base64Preview: string;
    url: string;
    filename: string;
    colorCompressedImage: File | null;
    monochromeCompressedImage: File | null;
  }
  interface HistoryMode extends Partial<Mode> {
    dateVisited: number;
    fullUrl: string;
  }
  interface WordlePlayerState {
    name: string;
    letters: string[][] | string;
    currWordIndex: number;
    correct: number;
    incorrect: number;
    lastActivityPingDateInMs: number;
  }
  interface HamburgerRoute {
    onClick: () => void;
    name: string;
    type: eModeType;
    id: string;
  }
  type SitchApiResponse = SitchApisuccessfulResponse & SitchApiErrorResponse;
  type FontVariant =
    | '100'
    | '100italic'
    | '200'
    | '200italic'
    | '300'
    | '300italic'
    | 'regular'
    | 'italic'
    | '500'
    | '500italic'
    | '600'
    | '600italic'
    | '700'
    | '700italic'
    | '800'
    | '800italic'
    | '900'
    | '900italic'
    | 'bold';
}

// Written by Douglas Crockford.
String.prototype.supplant = function supplant(o: string[]): string {
  return this.replace(/{([^{}]*)}/g, (a, b) => {
    const r = o[b];
    return typeof r === 'string' || typeof r === 'number' ? r : a;
  });
};

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
});

init();
